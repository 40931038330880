<template>
 <div class="flow-root">
  <ul role="list" class="mx-10 mt-5">
   <li v-for="(event, eventIdx) in callInfo.chain" :key="eventIdx">
    <div class="relative pb-8">
     <span
      v-if="eventIdx !== data.length - 1"
      class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
      aria-hidden="true"
     />
     <div class="relative flex space-x-3">
      <div>
       <span
        class="bg-gray-400 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
       >
        <font-awesome-icon
         :icon="returnCallDetail(event.elementType).icon"
         class="h-4 w-4 text-white"
         aria-hidden="true"
        />
       </span>
      </div>
      <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
       <div>
        <p v-if="event.elementType !== '1'" class="text-sm text-gray-500">
         {{ $t(returnCallDetail(event.elementType).type) }}
         - <span class="font-medium text-gray-900"> {{ event.element }}</span>
        </p>
        <p v-else class="text-sm text-gray-500">
         Extension {{ callInfo.last_extension }} ({{ callInfo.display_name }}) -
         <span class="font-medium text-gray-900 text-xs">Trunk 10000</span>
        </p>
       </div>
       <div class="whitespace-nowrap text-right text-sm text-gray-500">
        <!-- <time :datetime="event.datetime">{{ event.date }}</time> -->
       </div>
      </div>
     </div>
    </div>
   </li>
  </ul>
 </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
 name: "CallAnalyserDetail",
 props: ["id", "data", "callInfo"],
 components: {},

 data() {
  return {
   arrayOfParticipants: [],
   arrayOfExtensions: [],
   participants: {},
   actionId: {
    id0: "actionId.0",
    id5: "actionId.5",
    id6: "actionId.6",
    id9: "actionId.9",
    id10: "actionId.10",
    id13: "actionId.13",
    id15: "actionId.15",
    id101: "actionId.101",
    id102: "actionId.102",
    id103: "actionId.103",
    id104: "actionId.104",
    id400: "actionId.400",
    id408: "actionId.408",
    id409: "actionId.409",
    id418: "actionId.418",
    id425: "actionId.425",
   },
  };
 },
 methods: {
  async getPBXData() {
   try {
    const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/pbx3cxCalls/${this.id}`, {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    });
    this.participants = res.data.participants;
    return res.data.participants;
   } catch (error) {
    const status = error.response.status;
    this.$emit("setLoading", false);
    this.errorHandling(error);
    if (error.response.status === 500) {
     const msg = this.$t("error500");
     this.$router.push({
      name: "Error",
      query: { status, msg },
     });
    }
   }
  },
  getActionId(action_id) {
   if (action_id === 0) {
    return {
     id: action_id,
     action: this.$t(this.actionId.id0),
     icon: "user",
     color: "bg-gray-400",
    };
   } else if (action_id === "1") {
    return {
     id: action_id,
     action: "is initializing the call with success",
     icon: "user",
     color: "bg-gray-400",
    };
   } else if (action_id === "2") {
    return {
     id: action_id,
     action: "initialize call after audio prompt",
     icon: "user",
     color: "bg-gray-400",
    };
   } else if (action_id === "5") {
    return {
     id: action_id,
     action: this.$t(this.actionId.id5),
     icon: "user-check",
     color: "bg-green-500",
    };
   } else if (action_id === "6") {
    return {
     id: action_id,
     action: this.$t(this.actionId.id6),
     icon: "user-check",
     color: "bg-green-500",
    };
   } else if (action_id === "9") {
    return {
     id: action_id,
     action: this.$t(this.actionId.id9),
     icon: "user",
     color: "bg-gray-400",
    };
   } else if (action_id === "10") {
    return {
     id: action_id,
     action: this.$t(this.actionId.id10),
     icon: "user-clock",
     color: "bg-blue-500",
    };
   } else if (action_id === "13") {
    return {
     id: action_id,
     action: this.$t(this.actionId.id13),
     icon: "users",
     color: "bg-gray-400",
    };
   } else if (action_id === "15") {
    return {
     id: action_id,
     action: this.$t(this.actionId.id15),
     icon: "user-xmark",
     color: "bg-red-400",
    };
   } else if (action_id === "101") {
    return {
     id: action_id,
     action: this.$t(this.actionId.id101),
     icon: "user",
     color: "bg-gray-400",
    };
   } else if (action_id === "102") {
    return {
     id: action_id,
     action: this.$t(this.actionId.id102),
     icon: "user",
     color: "bg-gray-400",
    };
   } else if (action_id === "103") {
    return {
     id: action_id,
     action: this.$t(this.actionId.id103),
     icon: "user",
     color: "bg-gray-400",
    };
   } else if (action_id === "104") {
    return {
     id: action_id,
     action: this.$t(this.actionId.id104),
     icon: "user",
     color: "bg-gray-400",
    };
   } else if (action_id === "400") {
    return {
     id: action_id,
     action: this.$t(this.actionId.id400),
     icon: "user",
     color: "bg-gray-400",
    };
   } else if (action_id === "408") {
    return {
     id: action_id,
     action: this.$t(this.actionId.id408),
     icon: "user",
     color: "bg-gray-400",
    };
   } else if (action_id === "409") {
    return {
     id: action_id,
     action: this.$t(this.actionId.id409),
     icon: "user",
     color: "bg-gray-400",
    };
   } else if (action_id === "418") {
    return {
     id: action_id,
     action: this.$t(this.actionId.id418),
     icon: "user",
     color: "bg-gray-400",
    };
   } else if (action_id === "425") {
    return {
     id: action_id,
     action: this.$t(this.actionId.id425),
     icon: "user",
     color: "bg-gray-400",
    };
   } else {
    return {
     id: action_id,
     action: "unknown",
     icon: "user",
     color: "bg-gray-400",
    };
   }
  },
  async getDstParticipant() {
   let obj = {};
   let objTree = {};
   let objTreeData = {};
   objTreeData["data"] = [];
   let arr = [];
   let extensionArr = [];
   let extensionSameStartTimeArr = [];
   //  let participants = await this.getPBXData();
   this.arrayOfParticipants = [];
   let arrayOfPart = [];
   //  const participantKeys = Object.keys(participants);
   let segments = this.callInfo.chain;

   extensionSameStartTimeArr = this.hasSameTimeStamp(segments);

   segments.forEach((element) => {
    obj = {};
    obj["id"] = this.getActionId(element.act).id;
    obj["action"] = this.getActionId(element.act).action;
    obj["icon"] = this.getActionId(element.act).icon;
    obj["color"] = this.getActionId(element.act).color;
    obj["source"] = this.returnDstParticipantInfo(element);
    obj["dn"] = element.dst_dn;
    obj["display_name"] = element.dst_display_name;
    obj["role"] = element.role ? element.role : "";
    obj["start_time"] = element.dst_start_time;
    obj["order"] = element.seg_order;
    if (element.action_party_id) {
     obj["target"] = this.getTargetParticipant(element.action_party_id);
    }

    if (element.act != 1 && element.act != 2) {
     arr.push(obj);
    }
   });

   let duplicatedTimes = [];
   let duplicatedTimesFiltered = [];

   extensionSameStartTimeArr.forEach((el, index) => {
    duplicatedTimes.push(el.start_time);
   });

   duplicatedTimesFiltered = Array.from(new Set(duplicatedTimes));

   let arrayObjWithSameStartTime = this.getObjWithSameStartTime(
    extensionSameStartTimeArr,
    duplicatedTimesFiltered
   );

   arr = arr.sort((a, b) => {
    return a.sort - b.sort;
   });

   this.arrayOfParticipants = this.subtractArrays(arr, this.arrayOfExtensions);

   this.arrayOfParticipants = this.arrayOfParticipants.concat(arrayObjWithSameStartTime);
   this.arrayOfParticipants = this.sortParticipantsWithTree(this.arrayOfParticipants);
  },
  hasSameTimeStamp(segments) {
   let arr = [];
   let sameStartTimeArr = [];
   //  const participantKeys = Object.keys(obj);
   for (let index = 0; index < segments.length; index++) {
    const element = segments[index];
    // dn_type = 0 - extension
    // action_id != 1 - remove duplicate participant who initialize the call
    // dn_type != 0 - remove participant who starts the call (first participant of the call)
    if (element.act_dn_type === "0" && element.act != 1 && element.act != 0) {
     arr.push(element);
    }
   }

   const lookup = arr.reduce((a, e) => {
    a[e.start_time] = ++a[e.start_time] || 0;
    return a;
   }, {});

   sameStartTimeArr = arr.filter((e) => {
    return lookup[e.start_time];
   });
   return sameStartTimeArr;
  },
  getObjWithSameStartTime(extensionSameStartTimeArr, duplicatedTimesFiltered) {
   let objTree = {};
   let objTreeData = {};

   let arrayTest2 = [];
   let arrayTest = [];

   if (extensionSameStartTimeArr.length > 1) {
    for (let index = 0; index < extensionSameStartTimeArr.length; index++) {
     const element = extensionSameStartTimeArr[index];
     objTree = {};
     objTreeData = {};
     for (let indexTimes = 0; indexTimes < duplicatedTimesFiltered.length; indexTimes++) {
      objTree = {};
      const elementTimes = duplicatedTimesFiltered[indexTimes];
      if (element.start_time === elementTimes) {
       objTree["action"] = this.getActionId(element.act).action;
       objTree["icon"] = this.getActionId(element.act).icon;
       objTree["color"] = this.getActionId(element.act).color;
       objTree["source"] = this.returnDstParticipantInfo(element);
       objTree["role"] = element.role ? element.role : "";
       objTree["dn"] = element.dn;
       objTree["display_name"] = element.display_name;
       objTree["start_time"] = element.start_time;
       objTreeData["start_time"] = element.start_time;
       arrayTest2.push(objTree);
       arrayTest2 = arrayTest2.filter((el) => {
        return el.start_time === elementTimes;
       });
       objTreeData["data"] = arrayTest2;
       arrayTest.splice(indexTimes, 1, objTreeData);
       this.arrayOfExtensions.push(objTree);
      }
     }
    }
   }
   return arrayTest;
  },
  subtractArrays(arrParticipants, arrExtensions) {
   let subArr = arrParticipants;
   let indexArr = [];

   if (arrParticipants.length >= arrExtensions.length) {
    for (let index = 0; index < arrParticipants.length; index++) {
     const element = arrParticipants[index];
     for (let indexExt = 0; indexExt < arrExtensions.length; indexExt++) {
      const elementExt = arrExtensions[indexExt];
      if (element.dn == elementExt.dn) {
       indexArr.push(index);
      }
     }
    }
   } else {
    for (let indexExt = 0; indexExt < arrExtensions.length; indexExt++) {
     const elementExt = arrExtensions[indexExt];
     for (let index = 0; index < arrParticipants.length; index++) {
      const element = arrParticipants[index];
      if (element.dn === elementExt.dn) {
       subArr.splice(index, 1);
      }
     }
    }
   }
   // console.log("indexArr", indexArr);
   for (let index = indexArr.length - 1; index >= 0; index--) {
    subArr.splice(indexArr[index], 1);
   }

   return subArr;
  },
  getTimeStamp(time) {
   let timeStamp = new Date(time).getTime();
   return timeStamp;
  },
  sortParticipants(participants) {
   return participants.sort(function (a, b) {
    if (a.source && b.source) {
     let timeStampA = this.getTimeStamp(a.source[0].start_time);
     let timeStampB = this.getTimeStamp(b.source[0].start_time);
     return timeStampA - timeStampB;
    } else {
     return 1;
    }
   });
  },
  sortParticipantsWithTree(participants) {
   return participants.sort(function (a, b) {
    if (a.start_time && b.start_time) {
     let timeStampA = new Date(a.start_time).getTime();
     let timeStampB = new Date(b.start_time).getTime();
     return timeStampA - timeStampB;
    } else {
     return 1;
    }
   });
  },
  getTargetParticipant(partyId) {
   // let participants = await this.getPBXData();
   const participantKeys = Object.keys(this.participants);
   for (let index = 0; index < participantKeys.length; index++) {
    const key = participantKeys[index];
    const participant = this.participants[key];
    if (participant.id == partyId) {
     return this.returnDstParticipantInfo(participant);
    }
   }
  },
  returnDstParticipantInfo(participant) {
   if (participant.dst_dn_type == "0") {
    return [
     {
      type: "extension",
      name: participant.dst_display_name,
      number: participant.dst_caller_number,
      answer_time: participant.dst_answer_time,
      end_time: participant.dst_end_time,
      start_time: participant.dst_start_time,
     },
    ];
   } else if (participant.dst_dn_type == "1") {
    return [
     {
      type: "external number",
      name: participant.dst_display_name,
      number: participant.dst_caller_number,
      answer_time: participant.dst_answer_time,
      end_time: participant.dst_end_time,
      start_time: participant.dst_start_time,
     },
    ];
   } else if (participant.dst_dn_type == "4") {
    return [
     {
      type: "callqueue",
      name: participant.dst_display_name,
      number: participant.dst_caller_number,
      answer_time: participant.dst_answer_time,
      end_time: participant.dst_end_time,
      start_time: participant.dst_start_time,
     },
    ];
   } else if (participant.dst_dn_type == "5") {
    return [
     {
      type: "voicemail",
      name: participant.dst_display_name,
      number: participant.dst_caller_number,
      answer_time: participant.dst_answer_time,
      end_time: participant.dst_end_time,
      start_time: participant.dst_start_time,
     },
    ];
   } else if (participant.dst_dn_type == "6") {
    return [
     {
      type: "IVR",
      name: participant.dst_display_name,
      number: participant.dst_caller_number,
      answer_time: participant.dst_answer_time,
      end_time: participant.dst_end_time,
      start_time: participant.dst_start_time,
     },
    ];
   } else if (participant.dst_dn_type == "12") {
    return [
     {
      type: "unknown number",
      name: participant.dst_display_name,
      number: participant.dst_caller_number,
      answer_time: participant.dst_answer_time,
      end_time: participant.dst_end_time,
      start_time: participant.dst_start_time,
     },
    ];
   } else if (participant.dst_dn_type == "13") {
    return [
     {
      type: "external no response",
      name: participant.dst_display_name,
      number: participant.dst_caller_number,
      answer_time: participant.dst_answer_time,
      end_time: participant.dst_end_time,
      start_time: participant.dst_start_time,
     },
    ];
   } else if (participant.dst_dn_type == "14") {
    return [
     {
      type: "call flow",
      name: participant.dst_display_name,
      number: participant.dst_caller_number,
      answer_time: participant.dst_answer_time,
      end_time: participant.dst_end_time,
      start_time: participant.dst_start_time,
     },
    ];
   } else {
    return "unknown";
   }
  },
  returnCallDetail(data) {
   if (data == "0") {
    return {
     type: "extension",
     icon: "user",
    };
   } else if (data == "1") {
    return {
     type: "externalNumber",
     icon: "phone",
    };
   } else if (data == "4") {
    return {
     type: "callQueue",
     icon: "server",
    };
   } else if (data == "5") {
    return {
     type: "voicemailExt",
     icon: "user",
    };
   } else if (data == "6") {
    return {
     type: "IVR",
     icon: "fax",
    };
   } else if (data == "12") {
    return {
     type: "unknownNumber",
     icon: "question",
    };
   } else if (data == "13") {
    return {
     type: "external no response",
     icon: "user",
    };
   } else if (data == "14") {
    return {
     type: "callFlow",
     icon: "server",
    };
   } else {
    return "unknown";
   }
  },
 },
 computed: {
  ...mapState(["language"]),
 },
 mounted() {
  this.getDstParticipant();
 },
 watch: {
  language(val) {
   this.getDstParticipant();
  },
 },
};
</script>
